import React from "react";
import { PageWrapper } from "~components/Core";
import BreadCrumbSection from "~sections/about/BreadCrumb";
import AboutSection from '~sections/about/about'
import FeatureSection from '~sections/about/Feature'
import FooterFive from '~sections/agency/FooterFive'

export default function About() {
  return (
    <PageWrapper innerPage={true}>
        {/* <BreadCrumbSection title="About" /> */}
        <AboutSection/>
        <FeatureSection/>
        <FooterFive/>
    </PageWrapper>
  )
}
