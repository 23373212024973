import React from 'react'
import { StaticImage as Img } from 'gatsby-plugin-image'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from '~components';
import About from './style'
import SectionTitle from './Components/SectionTitle'
import Video from '~components/VideoModal'
export default function AboutSection(){
return(
<About>
  <Container>
    {/* about-us Content */}
    <Row className="align-items-center justify-content-center">
      <Col xs="12" className="col-lg-3 col-md-8 col-xs-10">
        <About.Image>
          <Img className="w-100" src="../../../assets/image/arc-symbol-7.png" alt="about" layout="fullWidth" placeholder="blurred"/>
        </About.Image>
      </Col>
      <Col col="12" className="col-lg-9 col-md-8 col-xs-10">
        <About.Content mt="40px" mtLG="0" mb="50px" mbLG="0" mlLG="30px">
              <SectionTitle 
                title="About Arc PR &amp; Communications"
                text="At Arc PR &amp; Communications, our aim is simple and powerful: to reach and build stronger connections with audiences that matter to your organization.<br class='d-none d-xs-block'>After 20+ years of leading communications teams, being a strategic advisor to CEOs and senior leaders of large global brands, principal Simon Townsend is expertly positioned to share insights and experience that help organizations harness the power of communications to achieve their goals. Arc leverages an extended team that is committed to always going above and beyond."
                titleProps={{mb:"30px"}}
                subTitleProps={{mb:"25px"}}
                />
        </About.Content>
      </Col>
    </Row>
    {/*/ .about-us Content */}
  </Container>
</About>

)
}

{/* <SectionTitleBlock 
subTitle="Watch video" 
title="Consultation Trade
Easier Than Ever"
text="Scale up and down easily as your business demands. And make use of business-grade consultation. Book flexibly by the day, week or longer and customise the layout to reflect your brand."
titleProps={{mb:"30px"}}
subTitleProps={{mb:"25px"}}
/> */}