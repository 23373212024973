import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Feature from './style'
import { StaticImage as Img } from 'gatsby-plugin-image'
import SectionTitle from './Components/SectionTitle'
import CounterBlock from './Components/CounterBlock'
export default function FeatureSection(){
return(
<Feature backgroundColor="#fff">
  <Container>
    {/* Section Title */}
    <Row>
      <Col className="col-xl-8 col-lg-10">
        <Feature.Box>
        <SectionTitle 
                subTitle="Story Behind Arc PR &amp; Communications" 
                title="Meet Simon Townsend"
                titleProps={{mb:"33px"}}
                subTitleProps={{mb:"20px"}}
                />
        </Feature.Box>
      </Col>
    </Row>
    <Row>
      <Col xs="12" className="col-lg-6 col-md-12 mb-5 mb-lg-7">
        <Feature.Box>
          <Feature.Text mrLG="30px">
            Simon is passionate about how strategic communications supports purpose-driven business results, corporate storytelling, and employee culture. He is always looking for opportunities to build communications capability, help leaders be better leaders, and inspire winning teams.
          </Feature.Text>
          <Feature.Text mrLG="30px">
            Messaging strategy is our core competency – passionate advocates of your brand.
          </Feature.Text>
        </Feature.Box>
      </Col>
      <Col xs="12" className="col-lg-6 col-md-12 mb-5 mb-lg-7">
        <Feature.Box>
          <Feature.Text mrLG="30px">
            Twenty-five years into his Corporate Communications career, Simon Townsend has collected a unique mix of PR agency, small business, technology, and large financial services industry experience through progressively senior communications leadership roles at some of the world’s largest institutions. As a trusted and proven senior communications advisor, he has led global teams, served multiple CEOs and senior leaders, and supported businesses from Toronto to Hong Kong.
          </Feature.Text>
        </Feature.Box>
      </Col>
    </Row>
    {/*/ .Section Title */}
    {/* Feature Content */}
    <Row className="align-items-center justify-content-center">
      <Col xs="12" className="col-lg-4 col-md-12">
        <Feature.Image mb="30px">
          <Img className="w-100" src="../../../assets/image/1644283809263.jpg" alt="simon" layout="fullWidth" placeholder="blurred"/>
        </Feature.Image>
      </Col>
      <Col className="col-lg-8 col-md-12">
        <Feature.Box>
          <Feature.Text mrLG="30px">
            Throughout his career, Simon has served as an executive member on a number of key enterprise committees including Sustainability, Sponsorship &amp; Philanthropy, Employee Experience, and Diversity &amp; Inclusion.
          </Feature.Text>
          <Feature.Text mrLG="30px">
            Simon graduated from the University of Western Ontario (Honours BA in Psychology) and holds a post-graduate certificate in Corporate Communications from Sheridan College.
          </Feature.Text>
          <Feature.Text mrLG="30px">
            Simon and his wife Nicole are the proud parents of two teenage girls, enjoying life in Toronto and Huntsville, Ontario. Outside of work, he is an ever-striving tennis player and guitarist.
          </Feature.Text>
        </Feature.Box>
      </Col>
    </Row>
    {/*/ .Feature Content */}
  </Container>
</Feature>

)
}